import React, { useEffect, useRef, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import { isEqual } from "lodash";
import { Popover, Overlay } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Table } from "../sharedComponents/table";
import Header from "../header";
import { setSideMenu } from "../Sidemenu/actions";
import {
	fetchUsers,
	fetchUserRoles,
	fetchCountryList,
	fetchLearningReason,
	fetchlanguagePreference,
	createUser,
	fetchGreekPronouncations,
	fetchHebrewPronouncations,
	fetchUserDetails,
	changeUserStatus,
	updateField,
	deleteUser,
	getUserRole,
	toggleSpeaking,
} from "./actions";
import { renderColumn } from "../../utils/tableHelper";
import { userListFields, languages } from "./helper";
import { UserDetails } from "./userDetails";
import UserForm from "./userForm";
import Loader from "../sharedComponents/Loader";
import SharedModal from "../sharedComponents/Modal";
import { getUserDetails } from "../../utils/verifyUser";
import ResumeProgressModal from "./resumeProgressModal";
import skipImg from "../../assets/images/skip.png";
import useOutsideClick from "../../hooks/useOutsideClick";

import "./users.scss";
import "./table.scss";
import "react-table/react-table.css";

const RenderOptions = ({
	id,
	showOption,
	setShowOption,
	isActive,
	setModalData,
	student,
	setResumeProgressModal,
	disableSpeaking,
	isRecording,
	currentUser,
}) => {
	const ref = useRef(null);
	const containerRef = useRef(null);
	const {t} = useTranslation();

	useOutsideClick(containerRef, () => {
		setShowOption(null);
	});

	const handleClick = (e) => {
		e.stopPropagation();
		if (showOption === id) {
			setShowOption(null);
		} else {
			setShowOption(id);
		}
	};

	return (
		<div ref={containerRef}>
			<div className="more-4" ref={ref} onClick={handleClick}>
				<i className="icon-more-4"></i>
			</div>
			{showOption === id && (
				<Overlay
					show={showOption === id}
					target={ref}
					placement="bottom"
					container={ref.current}
					containerPadding={20}
					id="popover-basic"
					className="popover-options">
					<Popover id="popover-contained">
						<Popover.Content>
							<div className="pl-1 pr-1">
								{student && (
									<>
										<Link
											to={`/home/Hebrew/Dashboard/${id}`}
											style={{ textDecoration: "none", color: "#000" }}>
											<span className="d-block mb-2">
												<i
													className={`icon pointer icon-speedometer-4`}
													style={{ marginRight: 20, marginLeft: 3 }}></i>
												{t("dashboard.name")}
											</span>
										</Link>
									</>
								)}
								{currentUser === "Administrator" && (
									<>
										<span className="d-block mb-2">
											<i
												className={`icon pointer ${isActive ? "icon-block" : " icon-tick-1"}`}
												onClick={() => setModalData(isActive ? "disable" : "enable")}
												style={{ marginRight: 20, marginLeft: 3 }}></i>
											{`${isActive ? "Disable" : "Enable"} User`}
										</span>
										{student && (
											<>
												<span className="d-block mb-2">
													<img
														src={skipImg}
														alt=""
														className="icon  pointer"
														onClick={() => {
															setResumeProgressModal(true);
														}}
														style={{ marginRight: 15 }}
													/>
													{t("dashboard.skiplesson")}
												</span>
											</>
										)}
										<span className="d-block mb-2">
											<i
												className="icon icon-write  pointer"
												onClick={() => {
													updateField("addUser", true);
													updateField("isEdit", true);
												}}
												style={{
													marginLeft: 4,
													marginRight: 20,
												}}></i>
											{t("helpers.utils.edit")}
										</span>
										<span className="d-block ">
											<i
												className="icon icon-garbage pointer"
												onClick={() => setModalData("delete")}
												style={{
													marginLeft: 5,
													marginRight: 15,
												}}></i>{" "}
											{t("helpers.utils.delete")}
										</span>
									</>
								)}
							</div>
						</Popover.Content>
					</Popover>
				</Overlay>
			)}
		</div>
	);
};

const Users = ({
	getUserRole,
	users,
	userRoles,
	preferences,
	greekPronouncations,
	hebrewPronouncations,
	userDetails,
	fetchUsers,
	fetchCountryList,
	setSideMenu,
	fetchUserRoles,
	fetchUserDetails,
	updateField,
	fetchLearningReason,
	fetchlanguagePreference,
	createUser,
	fetchGreekPronouncations,
	fetchHebrewPronouncations,
	deleteUser,
	changeUserStatus,
	addUser,
	isEdit,
	editProfile,
	match,
	history,
	isStudentMode,
	menu,
	toggleSpeaking,
}) => {
	let timer;
	const { t } = useTranslation();
	const [showResumeProgressModal, setResumeProgressModal] = useState(false);
	const [showDetails, setShowDetails] = useState(false);
	const [isClosingDetails, setClosingDetails] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [student, setStudent] = useState(true);
	const [isAdmin, setIsAdmin] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [saving, setSaving] = useState(false);
	const [userList, setUserList] = useState(false);
	const [showFilter, setShowFilter] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [action, setAction] = useState("");
	const [userFields, setUserFields] = useState(userListFields);
	const [userRole, setUserRole] = useState("");
	const [showOption, setShowOption] = useState(false);

	useEffect(() => {
		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const currentUser = getUserDetails() && getUserDetails().role && getUserDetails().role.description;

	useEffect(() => {
		const student = match.params.userType === "student";
		setStudent(student);
		updateField("list", null);
		getUserRole().then((res) => {
			if (res.response && res.response.role) {
				const userRole = res.response.role.description;
				setUserRole(userRole);
				if (userRole === "Student" || (userRole !== "Administrator" && !student)) {
					updateField("addUser", true);
					updateField("isEdit", true);
					updateField("editProfile", true);
					setSideMenu({
						menu: [{ title: t("header.myprofile.myprofileedit.name"), path: "" }],
						language: "",
					});
					const userId = res.response.id;
					if (!userDetails && userId) {
						fetchUserDetails(userId, userRole === "Student");
					}
				} else if (!editProfile) {
					const url = student ? "student/all" : "internaluser/all";
					fetchUsers(`${url}?Size=15000`);
					updateField("addUser", false);
					updateField("isEdit", false);
					updateField("userDetails", null);
					setSideMenu({
						menu: [{ title: student ? "Student" : "Internal", path: "" }],
						language: student ? "Student" : "Internal",
					});
				}
			}
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [match.params.userType, setSideMenu, updateField, fetchUsers, editProfile, getUserRole, fetchUserDetails]);

	useEffect(() => {
		const userDetails = getUserDetails();
		if (users && users.length > 0 && users.some((usr) => usr.id === userDetails.id)) {
			const newList = users.filter((usr) => usr.id !== userDetails.id);
			setUserList(newList);
		} else {
			setUserList(users);
		}
	}, [users]);

	useEffect(() => {
		fetchCountryList();
		fetchLearningReason();
		fetchlanguagePreference();
		fetchUserRoles();
		fetchGreekPronouncations();
		fetchHebrewPronouncations();
	}, [
		fetchUsers,
		setSideMenu,
		fetchUserRoles,
		fetchCountryList,
		fetchLearningReason,
		fetchlanguagePreference,
		fetchGreekPronouncations,
		fetchHebrewPronouncations,
		student,
		editProfile,
	]);

	useEffect(() => {
		const userDetails = getUserDetails();
		const isAdmin = (userDetails && userDetails.role && userDetails.role.role === 0) || userDetails.role.role === 2;
		setIsAdmin(isAdmin);
		const studentFields = [...userListFields.slice(0, 1), ...userListFields.slice(2, isAdmin ? 8 : 7)];
		const internalUserFields = [...userListFields.slice(0, 2), ...userListFields.slice(4, isAdmin ? 8 : 7)];
		if (student && !isEqual(userFields, studentFields)) {
			setUserFields(studentFields);
		} else if (!student && !isEqual(userFields, internalUserFields)) {
			setUserFields(internalUserFields);
		}
	}, [student, userFields]);

	const closeDetails = () => {
		setClosingDetails(true);
		timer = setTimeout(() => {
			setShowDetails(false);
			setClosingDetails(false);
		}, 1000);
	};

	const renderCell = (field, props) => {
		switch (field.accessor) {
			case "firstName":
				return props.original.firstName || props.original.lastName
					? `${props.original.firstName} ${props.original.lastName}`
					: "-";
			case "learningLanguages":
				return props.value && props.value.length > 0 ? props.value.join(", ") : "-";
			case "Action":
				return renderActions(props.original);
			default:
				return props.value ? props.value : " - ";
		}
	};

	const setModalData = (action) => {
		setAction(action);
		setShowModal(true);
	};

	const getOptions = (field) => {
		let options = [];
		if (field.accessor === "role" && userRoles && userRoles.length > 0) {
			options = userRoles.map((role) => {
				return { value: role.value, label: role.description };
			});
		} else if (field.accessor === "learningLanguages") {
			options = [...languages];
		}
		return options;
	};

	const columns = [
		...userFields.map((caseListField) =>
			renderColumn(
				{
					...caseListField,
					width: caseListField.label === "Action" && student ? 180 : caseListField.width,
				},
				renderCell,
				getOptions
			)
		),
	];

	const cellProps = (state, rowInfo, column) => {
		return {
			className: `${column.id} expanded pointer`,
			onClick: (event) => {
				if (column.id === "Action") {
					setSelectedUser(rowInfo.original);
					fetchUserDetails(rowInfo.original.id, student);
				} else {
					fetchUserDetails(rowInfo.original.id, student);
					setShowOption(false);
					setShowDetails(true);
				}
			},
		};
	};

	const rowGroupProps = (state, rowInfo, column) => {
		return {
			className: `expanded`,
		};
	};

	const theadFilterProps = (state, rowInfo, column) => {
		return {
			className: `${showFilter ? "show" : "hide"}`,
		};
	};

	const onCancel = () => {
		updateField("addUser", false);
		updateField("isEdit", false);
		updateField("userDetails", null);
		if (editProfile) {
			updateField("editProfile", false);
			history.push("/Profile");
		}
	};

	const getFieldValue = (data, field, label) => {
		const fieldNames = {
			experienceInSpeakingHebrew: "experienceInSpeaking",
			experienceInSpeakingGreek: "experienceInSpeaking",
			hebrewPronouncationType: "hebrewPronunciationType",
			greekPronouncationType: "greekPronunciationType",
			experienceInTeachingGreek: "experienceInTeaching",
			experienceInTeachingHebrew: "experienceInTeaching",
		};

		if (field[label] && field[label].value) {
			data[fieldNames[label]] = field[label].value;
		} else {
			data[fieldNames[label]] = 0;
		}
	};

	const setPayload = (payload, data) => {
		const hebrew = preferences.find((lang) => lang.description === "Hebrew");
		const greek = preferences.find((lang) => lang.description === "Greek");
		const hebrewDetails = {
			language: hebrew ? hebrew.value : "",
		};
		getFieldValue(hebrewDetails, data, "experienceInSpeakingHebrew");
		const greekDetails = {
			language: greek ? greek.value : "",
		};
		getFieldValue(greekDetails, data, "experienceInSpeakingGreek");
		const selectedLang = student ? data.subscribedLanguage : data.teachingLanguage;
		const lang = preferences.find((lang) => lang.description === selectedLang.label);
		let languages = [];
		if (student || isStudentMode) {
			getFieldValue(hebrewDetails, data, "hebrewPronouncationType");
			getFieldValue(greekDetails, data, "greekPronouncationType");
			if (student) {
				payload.learningReasonID = data.learningReasonID.value;
			}
		}
		if (!student) {
			getFieldValue(hebrewDetails, data, "experienceInTeachingHebrew");
			getFieldValue(greekDetails, data, "experienceInTeachingGreek");
			payload.role = data.role.value;
		}
		if (lang && lang.description) {
			if (lang.description === "Hebrew") {
				languages = [{ ...hebrewDetails }];
			} else if (lang.description === "Greek") {
				languages = [{ ...greekDetails }];
			}
		} else {
			languages = [{ ...hebrewDetails }, { ...greekDetails }];
		}
		payload = {
			...payload,
			languages: languages,
		};
		return payload;
	};

	const saveFormData = (data) => {
		setSubmitting(true);
		let payload = {
			...data,
			countryCode: data.countryCode.value,
			resetPasswordLinkURL: `${window.location.origin}/resetpassword`,
		};

		const method = userDetails ? "PUT" : "POST";
		const url = `${student ? "student" : "internaluser"}/${userDetails ? "edit" : "create"}`;

		payload = setPayload(payload, data);
		if (userDetails) {
			payload.id = userDetails.id;
			updateField("isEdit", false);
		}
		createUser(url, method, payload).then((res) => {
			setSubmitting(false);
			if (res.statusCode === 200) {
				NotificationManager.success(
					t("header.myprofile.notification.successfulluserprofile", {
						action: `${userDetails ? t("helpers.utils.update") : t("helpers.utils.created")}`,
					}),
					"",
					3000
				);
				updateField("addUser", false);
				updateField("userDetails", null);
				if (editProfile) {
					setUserDetails(payload);
					history.push("/Profile");
					updateField("editProfile", false);
				} else {
					const url = student ? "student/all" : "internaluser/all";
					fetchUsers(`${url}?Size=15000`);
				}
			} else {
				NotificationManager.error(res.response && res.response.message, t("helpers.utils.notification.error"), 2000);
			}
		});
	};

	const setUserDetails = (data) => {
		const userDetails = getUserDetails();
		if (userDetails.firstName !== data.firstName || userDetails.lastName !== data.lastName) {
			userDetails.firstName = data.firstName;
			userDetails.lastName = data.lastName;
			localStorage.setItem("auth", JSON.stringify({ response: { ...userDetails } }));
		}
	};

	const onModalClose = () => {
		setShowModal(false);
		setAction("");
		updateField("userDetails", null);
	};

	const deleteSelctedUser = () => {
		setSaving(true);
		let url = student ? "student/delete" : "internaluser/delete";
		url = `${url}?id=${userDetails.id}`;
		deleteUser(url).then((res) => {
			setSaving(false);
			onModalClose();
			if (res.statusCode === 200) {
				const listUrl = student ? "student/all" : "internaluser/all";
				fetchUsers(`${listUrl}?Size=15000`);
				NotificationManager.success(t("user.deleted.successfully"), "", 3000);
			} else {
				NotificationManager.error(t("helpers.utils.notification.error"), "", 3000);
			}
		});
	};

	const toggleUserStatus = () => {
		setSaving(true);
		const isActive = userDetails.isActive ? false : true;
		const payload = {
			id: userDetails.id,
			active: isActive,
		};
		changeUserStatus(payload).then((res) => {
			setSaving(false);
			onModalClose();
			if (res.statusCode === 200) {
				const listUrl = student ? "student/all" : "internaluser/all";
				fetchUsers(`${listUrl}?Size=15000`);
				NotificationManager.success(
					t("header.myprofile.notification.successfulluserprofile", {
						action: `${isActive ? t("helpers.utils.enabled") : t("helpers.utils.disabled")}`,
					}),
					"",
					3000
				);
			} else {
				NotificationManager.error(t("helpers.utils.notification.error"), "", 3000);
			}
		});
	};

	const disableSpeaking = async (id, isRecording, language) => {
		const resp = await toggleSpeaking(id, language, isRecording);
		if (resp.statusCode === 200) {
			setUserList(false);
			NotificationManager.success(
				t("helpers.utils.speaking", {
					language: `${language === 0 ? t("header.hebrew") : t("header.greek")}`,
					action: `${isRecording ? t("helpers.utils.enabled") : t("helpers.utils.disabled")}`,
				}),
				"",
				3000
			);
			fetchUsers(`student/all?Size=15000`);
		}
	};
	const renderActions = (data) => {
		const isActive = data.isActive;
		return (
			<div className="action-field">
				{student ? (
					<div className="d-flex justify-content-center">
						<RenderOptions
							id={data.id}
							isRecording={
								(match.params.language === "Hebrew" && data.isHebrewSpeakingEnabled) ||
								(match.params.language === "Greek" && data.isGreekSpeakingEnabled)
							}
							setShowOption={setShowOption}
							showOption={showOption}
							isActive={isActive}
							setModalData={setModalData}
							student={student}
							setResumeProgressModal={setResumeProgressModal}
							currentUser={currentUser}
						/>
						{data && data.learningLanguages.indexOf("Hebrew") > -1 && (
							<span
								title={`${
									data.isHebrewSpeakingEnabled ? "Disable speaking for hebrew" : "Enable speaking for hebrew"
								}`}
								className="d-block pointer"
								onClick={() => {
									disableSpeaking(data.id, !data.isHebrewSpeakingEnabled, 0);
									setShowOption(false);
								}}
								style={{ marginTop: 5 }}>
								<i
									className={`icon ${data.isHebrewSpeakingEnabled ? "icon-speech" : "icon-mute"} `}
									style={{ marginLeft: 10, fontSize: 15, marginRight: 7 }}></i>
								H
							</span>
						)}
						{data && data.learningLanguages.indexOf("Greek") > -1 && (
							<span
								title={`${
									data.isGreekSpeakingEnabled ? "Disable speaking for Greek" : "Enable speaking for Greek"
								}`}
								className="d-block pointer"
								onClick={() => {
									disableSpeaking(data.id, !data.isGreekSpeakingEnabled, 1);
									setShowOption(false);
								}}
								style={{ marginTop: 5 }}>
								<i
									className={`icon ${data.isGreekSpeakingEnabled ? "icon-speech" : "icon-mute"}`}
									style={{ marginLeft: 10, fontSize: 15, marginRight: 2 }}></i>{" "}
								G
							</span>
						)}
					</div>
				) : (
					<>
						<i
							title={`Click to ${isActive ? "disable" : "enable"} user`}
							className={`icon ml-4 mr-4 pointer ${isActive ? "icon-block" : " icon-tick-1"}`}
							onClick={() => setModalData(isActive ? "disable" : "enable")}></i>
						<i
							title="Edit"
							className="icon icon-write mr-4 pointer"
							onClick={() => {
								updateField("addUser", true);
								updateField("isEdit", true);
							}}></i>
						{student && (
							<img
								src={skipImg}
								alt=""
								title={t("helpers.utils.resumelesson")}								className="icon mr-4 pointer"
								onClick={() => {
									setResumeProgressModal(true);
								}}
							/>
						)}
						<i
							title={t("helpers.utils.delete")}
							className="icon icon-garbage pointer"
							onClick={() => setModalData("delete")}></i>
					</>
				)}
			</div>
		);
	};

	const renderModalBody = () => {
		return (
			<div className="text-center">
				<span>Are you sure you want to {action} this user?</span>
			</div>
		);
	};

	const renderModalFooter = () => {
		return (
			<div className="mb-2 delete-modal-footer">
				<button className="btn btn-cancel mr-3" onClick={onModalClose}>
					No
				</button>
				<button
					className="btn btn-upload"
					disabled={saving}
					onClick={action === "delete" ? deleteSelctedUser : toggleUserStatus}>
					Yes
				</button>
			</div>
		);
	};

	const handleViewCollection = (e) => {
		e.preventDefault();
		const filter = JSON.parse(localStorage.getItem("collectiveFilter"));
		let url = "/home/Hebrew/Dashboard/Collective";
		if (filter) {
			let filterPath = "?";
			filter.forEach((el) => {
				if (el.id === "learningLanguages") {
					let languages = "";
					el.value.forEach((item, idx) => {
						languages = languages + `${item.value}${idx !== el.value.length - 1 ? "," : ""}`;
					});
					filterPath = filterPath + `&${el.id[0].toUpperCase() + el.id.slice(1)}=${languages}`;
				} else {
					filterPath = filterPath + `&${el.id[0].toUpperCase() + el.id.slice(1)}=${el.value}`;
				}
			});
			url = url + filterPath;
		}
		history.push(url);
	};

	return (
		<div className="users-container w-100 flex-column empty">
			<ResumeProgressModal
				show={showResumeProgressModal}
				selectedUser={selectedUser}
				handleClose={() => {
					setSelectedUser(null);
					setResumeProgressModal(false);
				}}
			/>
			<SharedModal
				show={showModal}
				disableHeader={true}
				modalBody={renderModalBody()}
				modalFooter={renderModalFooter()}
				handleClose={onModalClose}
				modalClass="confirmation-modal"
			/>

			<Header head={editProfile ? t("header.myprofile.myprofileedit.name") : menu.length > 0 ? menu[0].title : ""} />
			<div className="d-flex flex-column">
				{!editProfile && (
					<div>
						<UserDetails
							show={showDetails}
							isAdmin={isAdmin}
							student={student}
							isClosing={isClosingDetails}
							editUser={() => {
								closeDetails();
								updateField("addUser", true);
								updateField("isEdit", true);
							}}
							close={() => {
								updateField("userDetails", null);
								closeDetails();
							}}
							details={userDetails}
							preferences={preferences}
						/>
					</div>
				)}

				{addUser ? (
					<UserForm
						addUser={saveFormData}
						onCancel={onCancel}
						student={student}
						updating={submitting}
						isEdit={isEdit}
						userRole={userRole}
					/>
				) : !userList ? (
					<Loader />
				) : (
					<div className="users-list mt-2 d-flex flex-column">
						<div className="d-flex w-100 justify-content-end">
							<button
								className="btn btn-primary mr-2 pointer"
								style={{
									background: "none",
									color: "#000",
									borderColor: "#19b5f7",
									fontSize: 14,
									fontWeight: 600,
								}}
								onClick={handleViewCollection}>
								View Collective
							</button>
							<button
								className="btn btn-primary add-user filter-btn mr-2"
								onClick={() => setShowFilter(!showFilter)}>
								Filter
							</button>
							{currentUser === "Administrator" && (
								<button
									className="btn btn-primary add-user"
									onClick={() => updateField("addUser", true)}
									disabled={!isAdmin}>
									<span>+</span> Add New User
								</button>
							)}
						</div>
						<div className="user-table mt-3 mb-3">
							<Table
								data={userList}
								columns={columns}
								cellProps={cellProps}
								rowGroupProps={rowGroupProps}
								theadFilterProps={theadFilterProps}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	users: state.users.list,
	preferences: state.users.preferences,
	userDetails: state.users.userDetails,
	userRoles: state.users.userRoles,
	addUser: state.users.addUser,
	isEdit: state.users.isEdit,
	editProfile: state.users.editProfile,
	greekPronouncations: state.users.greekPronouncations,
	hebrewPronouncations: state.users.hebrewPronouncations,
	isStudentMode: state.studentLanguageLearning.isStudentMode,
	menu: state.sideMenu.menu,
});

const mapDispatchToProps = {
	setSideMenu,
	fetchUsers,
	fetchUserRoles,
	fetchCountryList,
	fetchLearningReason,
	fetchlanguagePreference,
	createUser,
	fetchGreekPronouncations,
	fetchHebrewPronouncations,
	fetchUserDetails,
	deleteUser,
	changeUserStatus,
	updateField,
	getUserRole,
	toggleSpeaking,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));
